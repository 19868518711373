import './Showcase.css'
function Showcase () {
    return (
        <div className='showcase'>
            <a href='https://chrome.google.com/webstore/detail/yt-focus-learning-made-ea/fdekaebckbnpgafknooinjcnelmlhiip'>
                <img src='showcase.png' alt='app showcase'/>
            </a>
        </div>
    );
}

export default Showcase;